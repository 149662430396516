<template>
	<div id="home">

		<div class="ui center aligned header header-title">Université Mohammed V de Rabat</div>

		<div class="ui text container" style="position:relative">
			<svg class="absolute left-full transform translate-x-1/2 faded dots" width="404" height="404" fill="none"
			     viewBox="0 0 404 404">
				<defs>
					<pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20"
					         patternUnits="userSpaceOnUse">
						<rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"/>
					</pattern>
				</defs>
				<rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"/>
			</svg>
			<svg class="absolute right-full bottom-0 transform -translate-x-1/2 faded dots" width="404" height="404"
			     fill="none"
			     viewBox="0 0 404 404">
				<defs>
					<pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20"
					         patternUnits="userSpaceOnUse">
						<rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"/>
					</pattern>
				</defs>
				<rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"/>
			</svg>
			<div class="ui center aligned header header-title-2">
				Service des documents référentiels<br>
			</div>
				<p class="header-subtitle">Veuillez saisir le code au bas du document.</p>
				<div class="ui segment borderless-segment">
					<form class="ui big form">

						<div class="field" id="cne-field">
							<label>Code<span class="required">*</span></label>
							<input type="text" maxlength="10" name="code" size="20" v-model="token">
							<span class="error-message" id="cne-error"></span>
						</div>

						<p class="header-subtitle">Tous les champs sont obligatoires</p>
						<div class="field recaptcha_container" id="captcha-field">
							<label>Captcha<span class="required">*</span></label>
							<vue-recaptcha class="g-recaptcha"
							               ref="recaptcha"
							               :sitekey="site_key" size=""
							               @verify="onReCaptchaVerify"
							               @expired="onReCaptchaExpired"></vue-recaptcha>
							<span class="error-message" id="captcha-error"></span>
						</div>
						<button class="ui fluid big green-btn submit button" @click.prevent="verify" :disabled="!token">Afficher</button>
					</form>
				</div>
		</div>





	</div>
</template>

<script>

	const VueRecaptcha = () => import('vue-recaptcha');
	export default {
		name: 'home',
		components: {VueRecaptcha},
		data() {
			return {
				isBusy: false,
				is_mail_sent: false,
				site_key: '6LdXxvoUAAAAAAMq_HUtfYOGBBNR5-YEbuDzPqwm',
				captcha_response: "",
				notRobot: false,
				token:'',
				email_confirmation: '',
			}
		},
		computed: {
			Loading() {
				return this.$store.state.loading;
			},
			cycles() {
				return this.$store.state.cycles;
			}
		},
		methods: {
			sendRequest() {
				log('sendRequest');
				if(this.isBusy)
					return;

				const query = {
					token: this.token,
					'g-recaptcha-response': this.captcha_response
				};

				this.$store.dispatchAsync(this.$SHARED.services.verify, query, true).then((data) => {
					this.$router.push({name: 'document', params:{attachment: data}});
				}, code => {
					this.$notify({
						group: "user-message",
						type: "error",
						title: "Alerte",
						text: this.$SHARED.messages[code]
					});
					this.resetRecaptcha();

				}).finally(() => {
					this.captcha_response = '';
					this.isBusy = false;
				});
			},
			verify() {
				log('`verify`');
				if (this.Loading || this.isBusy)
					return;
				this.$notify({group: 'user-message', clean: true});

				let errors = 0;

				if (this.token === '') {
					$('#cne-field').addClass('error');
					$('#cne-error')
						.text("le code est obligatoire")
						.show();
					errors++;
				}

				if (!this.notRobot) {
					$('#captcha-field').addClass('error');
					$('#captcha-error')
						.text("Captcha est obligatoire")
						.show();
					errors++;
				}

				if (errors > 0) {
					return;
				}

				this.sendRequest();

			},
			onReCaptchaVerify(response) {
				this.notRobot = true;
				this.captcha_response = response;
			},
			onReCaptchaExpired() {
				this.notRobot = false;
				this.captcha_response = '';
			},
			resetRecaptcha() {
				this.$refs.recaptcha.reset() // Direct call reset method
			}
		},
		mounted(){
			if(this.$route.query.code){
				this.token = this.$route.query.code
			}
		}
	}

</script>

<style scoped>

	body {
		background-color: #DADADA;
	}

	body > .grid {
		height: 100%;
	}

	#login {
		margin-top: 100px;
	}

	.column {
		max-width: 450px;
	}

	.green-btn {
		background-color: #10A558 !important;
		color: #ffffff !important;
	}

	.green-btn:hover {
		background-color: #0E954F !important;
		color: #ffffff !important;
	}

	.borderless-segment {
		border: none !important;
		box-shadow: none !important;
	}

	.error-message {
		display: none;
		color: rgba(195, 82, 63, 0.75);
		height: 12px;
		font-weight: 600;
	}

	.required {
		color: red;
	}

</style>
